import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { Eye } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { registerApi } from "@api/auth.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { Button } from "@components/ui/button.tsx";
import { Card, CardContent, CardHeader } from "@components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import { registerRequestSchema, Register } from "@schemas/auth.schema.ts";
import { useUserStore } from "@stores";

const RegisterRoute = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const setUserEmail = useUserStore(state => state.setUserEmail);

  const register = useMutation({
    mutationFn: registerApi,
  });
  const form = useForm<Register>({
    resolver: zodResolver(registerRequestSchema),
    disabled: register.isPending,
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });

  const togglePassword = useCallback(() => setShowPassword(prev => !prev), []);

  const handleSubmit = useCallback(
    async (values: Register) => {
      await register.mutateAsync(values);

      setUserEmail(values.email);
      await navigate({
        to: "/verification-sent",
      });
    },
    [navigate, register, setUserEmail]
  );

  return (
    <div className="h-full grid place-items-center">
      <Card className="min-w-[30rem]">
        <CardHeader>
          <h1 className="text-2xl text-center">Welcome to Vetly!</h1>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-4"
            >
              {register.isError && <ApiAlert error={register.error} />}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="John Doe" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        inputMode="email"
                        placeholder="example@example.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                          onClick={togglePassword}
                        >
                          <Eye size={16} />
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-between items-center">
                <Button type="submit">Register</Button>
                <div className="space-x-4 underline">
                  <Link to="/login">Already have an account?</Link>
                </div>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RegisterRoute;
