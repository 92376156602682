import { Link, Outlet, useRouter } from "@tanstack/react-router";
import { ArrowLeft } from "lucide-react";

import { Button } from "@components/ui/button.tsx";

const PreferencesLayout = () => {
  const { history } = useRouter();

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center gap-4">
        <Button variant="link" size="icon" onClick={() => history.go(-1)}>
          <ArrowLeft />
        </Button>
        <h1 className="text-3xl font-semibold">Preferences</h1>
      </div>
      <div className="flex gap-36">
        <nav className="flex flex-col gap-4 text-sm text-muted-foreground">
          <Link
            to="/preferences/account"
            activeProps={{ className: "font-bold" }}
            replace
          >
            Account
          </Link>
          <Link
            to="/preferences/record-options"
            activeProps={{ className: "font-bold" }}
            replace
          >
            Record Options
          </Link>
        </nav>
        <div className="flex-1 max-w-5xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PreferencesLayout;
