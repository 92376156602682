import { Method } from "axios";
import { z } from "zod";

import axiosInstance from "@config/axiosInstance.ts";

export default function api<Request, Response>({
  method,
  path,
  requestSchema,
  responseSchema,
}: {
  method: Method;
  path: string;
  requestSchema: z.ZodType<Request>;
  responseSchema: z.ZodType<Response>;
}): (data: Request) => Promise<Response> {
  return async function (requestData: Request) {
    requestSchema.parse(requestData);

    const response = await axiosInstance({
      method,
      url: path,
      [method === "get" ? "params" : "data"]: requestData,
    });

    if (process.env.NODE_ENV === "production") {
      responseSchema.safeParseAsync(response.data).then(result => {
        if (!result.success) {
          // TODO: Send error to sentry or other error reporting service
        }
      });

      return response.data as Response;
    }

    return responseSchema.parse(response.data);
  };
}
