import { IconProps } from "@interfaces/icon.ts";
import { cn } from "@utils.ts";

export const FemaleGenderIcon = ({ className, size }: IconProps) => (
  <svg
    className={cn("fill-primary dark:fill-primary-dark", className)}
    width={size}
    height={size}
    viewBox="0 0 256 256"
  >
    <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
      <path
        d="M 65.896 50.433 c 11.522 -11.522 11.522 -30.27 0 -41.792 c -11.521 -11.522 -30.27 -11.522 -41.792 0 c -11.522 11.522 -11.522 30.269 0 41.792 C 29.102 55.432 35.461 58.259 42 58.92 v 12.09 H 29.485 v 6.001 H 42 V 90 H 48 V 77.012 h 12.514 v -6.001 H 48 V 58.92 C 54.539 58.259 60.898 55.432 65.896 50.433 z M 28.347 12.885 C 32.939 8.294 38.969 5.998 45 5.998 c 6.031 0 12.061 2.295 16.653 6.886 c 9.182 9.183 9.182 24.123 0 33.306 c -9.183 9.182 -24.124 9.181 -33.305 0 C 19.165 37.008 19.165 22.067 28.347 12.885 z"
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
    </g>
  </svg>
);
