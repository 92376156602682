import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { Eye } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

import { resetPasswordApi } from "@api/auth.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { Button } from "@components/ui/button.tsx";
import { Card, CardContent, CardHeader } from "@components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import {
  resetPasswordRequestSchema,
  ResetPassword,
} from "@schemas/auth.schema.ts";

const ResetPasswordRoute = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { userId, code } = useMatch({
    from: "/auth/reset-password",
    select: match => match.search,
  });

  const resetPassword = useMutation({
    mutationFn: resetPasswordApi,
  });

  const navigate = useNavigate();

  const form = useForm<ResetPassword>({
    resolver: zodResolver(resetPasswordRequestSchema),
    defaultValues: {
      code,
      userId,
      password: "",
    },
  });

  const togglePassword = useCallback(() => setShowPassword(prev => !prev), []);

  const handleSubmit = useCallback(
    async (values: ResetPassword) => {
      await resetPassword.mutateAsync(values);

      toast.success("Password updated!", {
        description: "Go to login to try out your new password :)",
      });
      await navigate({
        to: "/login",
      });
    },
    [navigate, resetPassword]
  );

  return (
    <div className="h-full grid place-items-center">
      <Card className="min-w-[30rem] max-w-[30rem]">
        <CardHeader className="text-center">
          <h1 className="text-2xl">Reset Password</h1>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              className="space-y-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              {resetPassword.isError && (
                <ApiAlert error={resetPassword.error} />
              )}
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                          onClick={togglePassword}
                        >
                          <Eye size={16} />
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button>Reset</Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPasswordRoute;
