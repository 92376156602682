import axios from "axios";
import { toast } from "sonner";

import authUtil from "@utils/auth.util.ts";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
});

axiosInstance.defaults.headers.common.Authorization = localStorage.getItem(
  import.meta.env.VITE_TOKEN_KEY
)
  ? `Bearer ${localStorage.getItem(import.meta.env.VITE_TOKEN_KEY)}`
  : undefined;

axiosInstance.interceptors.response.use(
  res => Promise.resolve(res),
  async error => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        toast.error("Session has expired. Please login again.");
        await authUtil.logout();
      }

      if (error.response?.data.message) {
        return Promise.reject(new Error(error.response.data.message));
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
