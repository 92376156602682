import { Outlet } from "@tanstack/react-router";

import Authorization from "@components/authorization.tsx";

const HomeLayout = () => {
  return (
    <div className="p-20">
      <Authorization>
        <Outlet />
      </Authorization>
    </div>
  );
};

export default HomeLayout;
