import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import axios from "axios";

import { Alert, AlertDescription, AlertTitle } from "@components/ui/alert.tsx";
import { badRequestSchema } from "@schemas/error.schema.ts";

interface ApiAlertProps {
  error: Error;
}

export const ApiAlert = ({ error }: ApiAlertProps) => {
  if (axios.isAxiosError(error) && error.response) {
    const data = badRequestSchema.parse(error.response.data);

    return (
      <Alert variant="destructive">
        <ExclamationTriangleIcon />
        <AlertTitle>Error</AlertTitle>
        {data[0].errors.issues.map(error => (
          <AlertDescription>{error.message}</AlertDescription>
        ))}
      </Alert>
    );
  }

  return (
    <Alert variant="destructive">
      <ExclamationTriangleIcon />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>{error.message}</AlertDescription>
    </Alert>
  );
};
