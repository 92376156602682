import { z } from "zod";

export const userSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string().email(),
  avatarUrl: z.string().url(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const loginRequestSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
  })
  .or(
    z.object({
      token: z.string(),
    })
  );

export const loginResponseSchema = z
  .object({
    token: z.string(),
  })
  .or(
    z.object({
      paymentIntent: z.string(),
    })
  );

export const registerRequestSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  password: z.string(),
});

export const forgotPasswordRequestSchema = z.object({
  email: z.string().email(),
});

export const resetPasswordSearchSchema = z.object({
  code: z.string(),
  userId: z.string().uuid(),
});

export const resetPasswordRequestSchema = z.object({
  code: z.string(),
  userId: z.string().uuid(),
  password: z.string(),
});

export const registerResponseSchema = userSchema;

export type Login = z.infer<typeof loginRequestSchema>;

export type Register = z.infer<typeof registerRequestSchema>;

export type ForgotPassword = z.infer<typeof forgotPasswordRequestSchema>;

export type ResetPassword = z.infer<typeof resetPasswordRequestSchema>;

export type User = z.infer<typeof userSchema>;
