import { Link } from "@tanstack/react-router";
import { ArrowRight, Mail } from "lucide-react";

import { Button } from "@components/ui/button.tsx";
import { Card, CardContent, CardHeader } from "@components/ui/card.tsx";
import { useUserStore } from "@stores";

const VerificationSentRoute = () => {
  const email = useUserStore(state => state.userEmail);

  return (
    <div className="h-full grid place-items-center">
      <Card className="min-w-[30rem] text-center">
        <CardHeader>
          <div className="flex flex-col items-center gap-8">
            <Mail size={70} />
            <h1 className="text-2xl">Check your email!</h1>
          </div>
        </CardHeader>
        <CardContent className="grid gap-8">
          <p>
            We just sent a verification link to <br />
            <span className="underline">{email}</span>
          </p>
          <Link to="/login">
            <Button>
              Go to login <ArrowRight size={20} className="ml-3" />
            </Button>
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default VerificationSentRoute;
