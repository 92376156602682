import { z } from "zod";

import { userSchema } from "@schemas/auth.schema.ts";

export const resultSchema = z.object({
  id: z.string().uuid(),
  value: z.string(),
  isDefault: z.boolean(),
  order: z.number().positive().min(1),
  reportId: z.string().uuid().nullable(),
  user: userSchema.optional(),
  userId: z.string().uuid().nullable(),
  objectiveId: z.string().uuid(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const personalizedResultSchema = z.object({
  value: z.string(),
  order: z.number().positive().min(1),
  isDefault: z.boolean().optional(),
});

export const createUserResultSchema = z.object({
  value: z.string().min(1, "Name is required."),
});

export const updateUserResultOrderSchema = z.object({
  order: z.number().positive().min(1),
});

export const createPersonalizedResultsSchema = z.object({
  objectiveId: z.string().uuid(),
  reportId: z.string().uuid(),
  boundTo: z.union([z.literal("user"), z.literal("report")]),
  results: personalizedResultSchema.array(),
});

export type Result = z.infer<typeof resultSchema>;

export type CreateUserResult = z.infer<typeof createUserResultSchema>;
