import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { capitalize, groupBy } from "lodash";
import { Cat, Dog, Trash } from "lucide-react";
import { Fragment, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

import {
  createReportApi,
  deleteReportApi,
  getReportsApi,
} from "@api/reports.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { FemaleGenderIcon } from "@components/images/female-gender.icon.tsx";
import { MaleGenderIcon } from "@components/images/male-gender.icon";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import { Label } from "@components/ui/label.tsx";
import { Loader } from "@components/ui/loader.tsx";
import { Separator } from "@components/ui/separator.tsx";
import {
  CreateReport,
  createReportSchema,
  PetType,
  Report,
  ReportStatus,
} from "@schemas/report.schema.ts";
import { useUserStore } from "@stores";

const headerStyles: Record<ReportStatus, string> = {
  DRAFT: "",
  READY: "text-lime-600 dark:text-lime-400",
  COMPLETED: "text-primary/60 dark:text-primary-dark/60",
};

const HomeRoute = () => {
  const {
    data: reports,
    isLoading: isReportsLoading,
    isError: isReportsError,
    error: reportsError,
  } = useQuery({
    queryKey: ["reports"],
    queryFn: () => getReportsApi(),
  });
  const createReport = useMutation({
    mutationKey: ["create-report"],
    mutationFn: createReportApi,
  });
  const deleteReport = useMutation({
    mutationKey: ["delete-report"],
    mutationFn: deleteReportApi,
  });
  const user = useUserStore(state => state.user!);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const form = useForm<CreateReport>({
    resolver: zodResolver(createReportSchema),
    defaultValues: {
      patientName: "",
      patientAge: 0,
      patientGender: undefined,
      patientType: PetType.DOG,
    },
  });

  const groupedReports = useMemo(
    () => ({
      ...(groupBy(reports, "status") as unknown as Record<
        ReportStatus,
        Report[] | undefined
      >),
    }),
    [reports]
  );

  const handleCreateReport = useCallback(
    async (values: CreateReport) => {
      const report = await createReport.mutateAsync(values);

      toast.success(
        `Record for ${report.patientName} has been created successfully.`,
        { description: "Please fill out the symptoms." }
      );
      await navigate({
        to: `/record/$id`,
        params: {
          id: report.id,
        },
      });
    },
    [createReport, navigate]
  );

  const handleDeleteReport = useCallback(
    async (id: string) => {
      await deleteReport.mutateAsync(id);

      await queryClient.invalidateQueries({
        queryKey: ["reports"],
      });

      toast.success("Record has been deleted successfully.");
    },
    [deleteReport, queryClient]
  );

  if (isReportsLoading || !user) {
    return <Loader />;
  }

  return (
    <div className="grid gap-8">
      {isReportsError && <ApiAlert error={reportsError} />}
      <div className="flex gap-10 items-center">
        <h2>{user.name}'s records</h2>
        <Dialog
          onOpenChange={open => {
            if (!open) form.reset();
          }}
        >
          <DialogTrigger asChild>
            <Button>+ Add new</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create new record</DialogTitle>
              <DialogDescription>
                Add some information for the pet that you are going to take a
                look at.
              </DialogDescription>
            </DialogHeader>
            <Form {...form}>
              <form
                className="space-y-4"
                onSubmit={form.handleSubmit(handleCreateReport)}
              >
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Patient Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Koko" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                  name="patientName"
                  control={form.control}
                />
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Age</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                  name="patientAge"
                  control={form.control}
                />
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Pet Type</FormLabel>
                      <FormControl>
                        <div className="flex gap-4">
                          <Input
                            {...field}
                            className="hidden peer/dog"
                            type="radio"
                            name="patientType"
                            value={PetType.DOG}
                            id="dog"
                          />
                          <Label
                            className="cursor-pointer peer-checked/dog:*:stroke-lime-600 dark:peer-checked/dog:*:stroke-lime-400"
                            htmlFor="dog"
                          >
                            <Dog />
                          </Label>
                          <Input
                            {...field}
                            className="hidden peer/cat"
                            type="radio"
                            name="patientType"
                            value={PetType.CAT}
                            id="cat"
                          />
                          <Label
                            className="cursor-pointer peer-checked/cat:*:stroke-lime-600 dark:peer-checked/cat:*:stroke-lime-400"
                            htmlFor="cat"
                          >
                            <Cat />
                          </Label>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                  name="patientType"
                  control={form.control}
                />
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Gender</FormLabel>
                      <FormControl>
                        <div className="flex gap-4">
                          <Input
                            {...field}
                            className="hidden peer/male"
                            type="radio"
                            name="patientGender"
                            value="MALE"
                            id="male"
                          />
                          <Label
                            className="cursor-pointer peer-checked/male:*:fill-lime-600 dark:peer-checked/male:*:fill-lime-400"
                            htmlFor="male"
                          >
                            <MaleGenderIcon size={30} />
                          </Label>
                          <Input
                            {...field}
                            className="hidden peer/female"
                            type="radio"
                            name="patientGender"
                            value="FEMALE"
                            id="female"
                          />
                          <Label
                            className="cursor-pointer peer-checked/female:*:fill-lime-600 dark:peer-checked/female:*:fill-lime-400"
                            htmlFor="female"
                          >
                            <FemaleGenderIcon size={30} />
                          </Label>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                  name="patientGender"
                  control={form.control}
                />
                <DialogFooter>
                  <Button type="submit">Create</Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      {Object.keys(ReportStatus).map(status => (
        <Fragment key={status}>
          <Separator />
          <h3 className={headerStyles[status]}>{capitalize(status)}</h3>
          <div className="ml-8 grid gap-4">
            {groupedReports[status]?.map(report => (
              <div className="flex items-center gap-4" key={report.id}>
                <Link to="/record/$id" params={{ id: report.id }}>
                  {report.patientName}
                </Link>

                {status === "COMPLETED" && (
                  <Button
                    variant="destructive"
                    size="icon"
                    onClick={() => handleDeleteReport(report.id)}
                  >
                    <Trash size={18} />
                  </Button>
                )}
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default HomeRoute;
