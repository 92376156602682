import { z } from "zod";

export const badRequestSchema = z.array(
  z.object({
    type: z.enum(["Body", "Params", "Query"]),
    errors: z.object({
      name: z.literal("ZodError"),
      issues: z.array(
        z
          .object({
            code: z.string(),
            message: z.string(),
            path: z.array(z.string()),
          })
          .passthrough()
      ),
    }),
  })
);
