import { create } from "zustand";

import { User } from "@schemas/auth.schema.ts";

interface UserStore {
  user?: User;
  userEmail?: string;
  setUser: (user: User) => void;
  setUserEmail: (email: string) => void;
}

export const useUserStore = create<UserStore>(set => ({
  user: undefined,
  userEmail: undefined,
  setUser: (user: User) => set({ user }),
  setUserEmail: (email: string) => set({ userEmail: email }),
}));
