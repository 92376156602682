import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { Eye } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { loginApi } from "@api/auth.api.ts";
import { Alert, AlertDescription } from "@components/ui/alert.tsx";
import { Button } from "@components/ui/button.tsx";
import { Card, CardContent, CardHeader } from "@components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import axiosInstance from "@config/axiosInstance.ts";
import { loginRoute } from "@router.tsx";
import { Login, loginRequestSchema } from "@schemas/auth.schema.ts";
import authUtil from "@utils/auth.util.ts";

const LoginRoute = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const params = loginRoute.useSearch();

  const login = useMutation({
    mutationFn: loginApi,
  });
  const form = useForm<Login>({
    resolver: zodResolver(loginRequestSchema),
    disabled: login.isPending,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (params.oneTimeToken) {
      (async () => {
        try {
          axiosInstance.defaults.headers.common.Authorization = "";

          const res = await login.mutateAsync({ token: params.oneTimeToken! });

          if ("token" in res) {
            authUtil.login(res.token);
            await navigate({ to: "/" });
          }
        } catch (err) {
          await navigate({
            to: "/login",
          });
        }
      })();
    }
  }, [params.oneTimeToken]);

  const togglePassword = useCallback(() => setShowPassword(prev => !prev), []);

  const handleSubmit = useCallback(
    async (values: Login) => {
      const res = await login.mutateAsync(values);

      if ("paymentIntent" in res) {
        window.location.href = res.paymentIntent;
        return;
      }

      authUtil.login(res.token);
      await navigate({ to: "/" });
    },
    [login, navigate]
  );

  return (
    <div className="h-full grid place-items-center">
      <Card className="min-w-[30rem]">
        <CardHeader>
          <h1 className="text-2xl text-center">Welcome to Vetly!</h1>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-4"
            >
              {params.canceled && (
                <Alert variant="destructive">
                  <AlertDescription>
                    We are sorry to see you leave!
                  </AlertDescription>
                </Alert>
              )}
              {login.isError && (
                <Alert variant="destructive">
                  <AlertDescription>{login.error.message}</AlertDescription>
                </Alert>
              )}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        inputMode="email"
                        placeholder="example@example.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                          onClick={togglePassword}
                        >
                          <Eye size={16} />
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-between items-center">
                <Button type="submit">Log in</Button>
                <div className="space-x-4 underline">
                  <Link to="/forgot-password">Forgot Password</Link>
                  <Link to="/register">Not yet registered?</Link>
                </div>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginRoute;
