import { arrayMove } from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Trash } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { getObjectivesApi } from "@api/reports.api.ts";
import {
  createUserResultApi,
  deleteUserResultApi,
  getResultsApi,
  updateUserResultOrderApi,
} from "@api/results.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import EditResult from "@components/edit-result.tsx";
import { Button } from "@components/ui/button.tsx";
import { DataTable } from "@components/ui/data-table.tsx";
import { DragHandle } from "@components/ui/drag-handle.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import { Loader } from "@components/ui/loader.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select.tsx";
import {
  CreateUserResult,
  createUserResultSchema,
  Result,
} from "@schemas/result.schema.ts";

const RecordOptionsRoute = () => {
  const [selectedObjectiveId, setSelectedObjectiveId] = useState<string>();

  const queryClient = useQueryClient();

  const {
    data: objectives,
    isLoading: isObjectivesLoading,
    isError: isObjectivesError,
    error: objectivesError,
  } = useQuery({
    queryKey: ["objectives"],
    queryFn: () => getObjectivesApi(),
  });

  const {
    refetch: fetchResults,
    data: results,
    isLoading: isResultsLoading,
    isError: isResultsError,
    error: resultsError,
  } = useQuery({
    queryKey: [selectedObjectiveId],
    queryFn: () => getResultsApi(selectedObjectiveId!)(),
    enabled: false,
  });

  const createUserResult = useMutation({
    mutationKey: ["create", "user", "result"],
    mutationFn: ({
      objectiveId,
      ...values
    }: CreateUserResult & { objectiveId: string }) =>
      createUserResultApi(objectiveId)(values),
  });

  const createOptionForm = useForm({
    resolver: zodResolver(createUserResultSchema),
    defaultValues: {
      value: "",
    },
  });

  useEffect(() => {
    if (selectedObjectiveId) {
      fetchResults();
    }
  }, [selectedObjectiveId]);

  const handleCreateOption = useCallback(
    async (values: CreateUserResult) => {
      if (!selectedObjectiveId) return;

      await createUserResult.mutateAsync({
        ...values,
        objectiveId: selectedObjectiveId,
      });

      await fetchResults();
      createOptionForm.reset();
    },
    [createOptionForm, createUserResult, fetchResults, selectedObjectiveId]
  );

  const handleResultDelete = useCallback(
    async (resultId: string) => {
      if (!selectedObjectiveId) return;

      await deleteUserResultApi(selectedObjectiveId, resultId)();

      await fetchResults();
    },
    [fetchResults, selectedObjectiveId]
  );

  const handleReorder = useCallback(
    async (resultId: string, order: number) => {
      if (!selectedObjectiveId) return;

      const prevData = queryClient.getQueryData<Result[]>([
        selectedObjectiveId,
      ]);

      queryClient.setQueryData(
        [selectedObjectiveId],
        arrayMove(
          prevData!,
          prevData!.findIndex(r => r.id === resultId),
          order - 1
        )
      );

      await updateUserResultOrderApi(selectedObjectiveId, resultId)({ order });

      await fetchResults();
    },
    [fetchResults, queryClient, selectedObjectiveId]
  );

  const optionsColumns = useMemo<ColumnDef<Result>[]>(
    () => [
      {
        id: "drag-handle",
        cell: ({ row }) =>
          !row.original.isDefault && <DragHandle rowId={row.id} />,
      },
      { accessorKey: "value", header: "Name" },
      {
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex gap-2 items-center">
            <EditResult result={row.original} fetchResults={fetchResults} />
            {!row.original.isDefault && (
              <Button
                variant="destructive"
                size="icon"
                onClick={() => handleResultDelete(row.id)}
              >
                <Trash />
              </Button>
            )}
          </div>
        ),
      },
    ],
    [handleResultDelete]
  );

  if (isObjectivesLoading) return <Loader />;
  if (isObjectivesError) return <ApiAlert error={objectivesError} />;

  if (!objectives) return null;

  return (
    <div className="grid gap-8">
      <h2 className="text-slate-500 dark:text-slate-400 max-w-prose">
        Select an objective to see the options related to that objective and
        after that you will be able to add, remove, reorder options as you like.
      </h2>

      <Select
        value={selectedObjectiveId}
        onValueChange={setSelectedObjectiveId}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select objective..." />
          <SelectContent>
            {objectives.map(objective => (
              <SelectItem key={objective.id} value={objective.id}>
                {objective.name}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectTrigger>
      </Select>

      {isResultsLoading && <Loader />}
      {isResultsError && <ApiAlert error={resultsError} />}
      {results && (
        <div className="grid gap-8">
          <Form {...createOptionForm}>
            <form
              className="flex"
              onSubmit={createOptionForm.handleSubmit(handleCreateOption)}
            >
              {createUserResult.isError && (
                <ApiAlert error={createUserResult.error} />
              )}
              <FormField
                control={createOptionForm.control}
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormControl>
                      <Input
                        {...field}
                        className="rounded-tr-none rounded-br-none"
                        placeholder="Enter a new option"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                name="value"
              />
              <Button className="rounded-tl-none rounded-bl-none w-1/5">
                Add
              </Button>
            </form>
          </Form>

          <DataTable
            columns={optionsColumns}
            data={results}
            onReorder={handleReorder}
          />
        </div>
      )}
    </div>
  );
};

export default RecordOptionsRoute;
