import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "@tanstack/react-router";
import { ArrowLeft } from "lucide-react";
import { useMemo } from "react";

import { getObjectivesApi, getReportApi } from "@api/reports.api";
import { ApiAlert } from "@components/api-alert.tsx";
import { ObjectiveFooter } from "@components/objective-footer.tsx";
import { ResultList } from "@components/result-list";
import { Loader } from "@components/ui/loader.tsx";
import { Stepper, StepperFooter, StepperItem } from "@components/ui/stepper";
import { SelectedResult } from "@schemas/objective.schema.ts";

const RecordRoute = () => {
  const params = useParams({ strict: false });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["reports", params.id],
    queryFn: () => getReportApi(params.id),
  });

  const {
    data: objectives,
    isLoading: isObjectivesLoading,
    isError: isObjectivesError,
    error: objectivesError,
  } = useQuery({
    queryKey: ["objectives"],
    queryFn: () => getObjectivesApi(),
  });

  const groupedResults = useMemo(
    () =>
      data?.selectedResults.reduce<Record<string, SelectedResult[]>>(
        (acc, selectedResult) => {
          if (acc[selectedResult.objectiveId])
            return {
              ...acc,
              [selectedResult.objectiveId]: [
                ...acc[selectedResult.objectiveId],
                selectedResult,
              ],
            };

          return {
            ...acc,
            [selectedResult.objectiveId]: [selectedResult],
          };
        },
        {}
      ) ?? {},
    [data]
  );

  if (isLoading) return <Loader />;
  if (isError) return <ApiAlert error={error} />;
  if (isObjectivesLoading) return <Loader />;
  if (isObjectivesError) return <ApiAlert error={objectivesError} />;

  if (data && objectives)
    return (
      <div>
        <div className="flex gap-4 items-center mb-8">
          <Link to="/">
            <ArrowLeft />
          </Link>
          <h2 className="text-2xl font-bold">{data.patientName}</h2>
        </div>
        <Stepper
          scrollTracking
          orientation="vertical"
          initialStep={
            data.status === "DRAFT" ? 0 : Object.keys(groupedResults).length
          }
          steps={objectives
            .sort((a, b) => a.order - b.order)
            .map(objective => ({
              label: objective.name,
              description: groupedResults[objective.id]
                .map(selectedResult => selectedResult.result.value)
                .join(" / "),
            }))}
        >
          {objectives.map((objective, index) => (
            <StepperItem key={objective.id}>
              <ResultList
                objectiveId={objective.id}
                selectedResults={groupedResults[objective.id]}
                reportId={params.id}
                isLast={index === objectives.length - 1}
              />
            </StepperItem>
          ))}
          <StepperFooter>
            <ObjectiveFooter reportId={params.id} />
          </StepperFooter>
        </Stepper>
      </div>
    );
};

export default RecordRoute;
