import { zodResolver } from "@hookform/resolvers/zod";
import { QueryObserverBaseResult, useMutation } from "@tanstack/react-query";
import { Pen } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { updateUserResultApi } from "@api/results.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import {
  CreateUserResult,
  createUserResultSchema,
  Result,
} from "@schemas/result.schema.ts";

interface EditResultProps {
  result: Result;
  fetchResults: QueryObserverBaseResult["refetch"];
}

const EditResult = ({ result, fetchResults }: EditResultProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<CreateUserResult>({
    resolver: zodResolver(createUserResultSchema),
    defaultValues: {
      value: result.value,
    },
  });

  const updateResult = useMutation({
    mutationKey: ["update", "user", "result", result.id],
    mutationFn: updateUserResultApi(result.objectiveId, result.id),
  });

  const handleSubmit = useCallback(
    async (values: CreateUserResult) => {
      await updateResult.mutateAsync(values);

      await fetchResults();
      setOpen(false);
    },
    [fetchResults, updateResult]
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary" size="icon">
          <Pen />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Result</DialogTitle>
          <DialogDescription>Edit the result name</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="grid gap-4"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            {updateResult.isError && <ApiAlert error={updateResult.error} />}
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              name="value"
              control={form.control}
            />
            <Button type="submit">Save</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditResult;
