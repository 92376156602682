import {
  closestCorners,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { Trash2 } from "lucide-react";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { createPersonalizedResultsApi } from "@api/results.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import { DragHandle } from "@components/ui/drag-handle.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import { Result } from "@schemas/result.schema.ts";

interface EditObjectiveResultsProps {
  results: Result[];
  reportId: string;
  objectiveId: string;
}

interface OptionFieldProps {
  result: Result;
  index: number;
  remove: (index: number) => void;
}

const blankResult: Result = {
  order: 0,
  id: "",
  isDefault: false,
  value: "",
  createdAt: "",
  objectiveId: "",
  updatedAt: "",
  reportId: null,
  userId: null,
};

export const EditObjectiveResults = ({
  results,
  reportId,
  objectiveId,
}: EditObjectiveResultsProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      results,
    },
  });
  const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: "results",
  });

  const createPersonalizedResults = useMutation({
    mutationKey: ["personalized-results"],
    mutationFn: createPersonalizedResultsApi,
  });

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => fields?.map(({ id }) => id),
    [fields]
  );

  const queryClient = useQueryClient();

  const handleReorder = useCallback(({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over.id) {
      move(
        active.data.current?.sortable.index,
        over.data.current?.sortable.index
      );
    }
  }, []);

  const handleSubmit = useCallback(
    async (values: { results: Result[] }) => {
      if (_.isEqual(results, values.results)) return setOpen(false);

      await createPersonalizedResults.mutateAsync({
        objectiveId,
        reportId,
        results: values.results.map((result, i) => ({
          ...result,
          order: i + 1,
        })),
        boundTo: "report",
      });

      await queryClient.invalidateQueries({
        queryKey: ["reports", reportId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["results", objectiveId],
      });

      setOpen(false);
    },
    [createPersonalizedResults, objectiveId, queryClient, reportId, results]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  useEffect(() => {
    form.reset({ results });
  }, [form, results]);

  return (
    <Dialog
      open={open}
      onOpenChange={open => {
        setOpen(open);
        form.reset();
      }}
    >
      <DialogTrigger asChild>
        <Button>Edit / Add</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add / Edit Options</DialogTitle>
          <DialogDescription>
            Here you can add, update or delete options from the list and save it
            only for the current record.
            <br />
            <br />
            <strong>Disclaimer: This will not affect any other records.</strong>
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(
              handleSubmit as SubmitHandler<{ results: Result[] }>
            )}
            className="grid gap-2"
          >
            {createPersonalizedResults.isError && (
              <ApiAlert error={createPersonalizedResults.error} />
            )}
            <DndContext
              collisionDetection={closestCorners}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleReorder}
              sensors={sensors}
            >
              <SortableContext
                items={dataIds}
                strategy={verticalListSortingStrategy}
              >
                {fields.map((result, index) => (
                  <OptionField
                    key={result.id}
                    result={result}
                    index={index}
                    remove={remove}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <DialogFooter className="mt-6">
              <Button
                type="button"
                onClick={() =>
                  append({
                    ...blankResult,
                    order: fields.length + 1,
                    id: crypto.randomUUID(),
                  })
                }
                variant="link"
              >
                + New option
              </Button>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const OptionField = ({ result, index, remove }: OptionFieldProps) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: result.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.6 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    <FormField
      render={({ field }) => (
        <FormItem ref={setNodeRef} style={style}>
          <FormControl>
            <div className="flex gap-2">
              <DragHandle rowId={result.id} />
              <Input {...field} className="max-w-72" />
              {!result.isDefault && (
                <Button
                  onClick={() => remove(index)}
                  size="icon"
                  variant="link"
                >
                  <Trash2 />
                </Button>
              )}
            </div>
          </FormControl>
        </FormItem>
      )}
      name={`results.${index}.value`}
    />
  );
};
