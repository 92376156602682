import { useSortable } from "@dnd-kit/sortable";
import { GripHorizontal } from "lucide-react";

import { Button } from "@components/ui/button.tsx";

interface DragHandleProps {
  rowId: string;
}

export const DragHandle = ({ rowId }: DragHandleProps) => {
  const { attributes, listeners } = useSortable({ id: rowId });

  return (
    <Button
      {...attributes}
      {...listeners}
      type="button"
      variant="link"
      size="icon"
    >
      <GripHorizontal />
    </Button>
  );
};
