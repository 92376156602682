import { z } from "zod";

import { selectedResultSchema } from "@schemas/objective.schema.ts";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum PetType {
  DOG = "DOG",
  CAT = "CAT",
}

export enum ReportStatus {
  DRAFT = "DRAFT",
  READY = "READY",
  COMPLETED = "COMPLETED",
}

export const reportSchema = z.object({
  id: z.string().uuid(),
  patientName: z.string(),
  patientAge: z.number().positive().min(0),
  patientGender: z.nativeEnum(Gender),
  patientType: z.nativeEnum(PetType),
  status: z.nativeEnum(ReportStatus),
  userId: z.string().uuid(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const fullReportSchema = reportSchema.merge(
  z.object({
    selectedResults: z.array(selectedResultSchema),
  })
);

export const createReportSchema = z.object({
  patientName: z.string(),
  patientAge: z.coerce.number().positive().min(0),
  patientGender: z.nativeEnum(Gender),
  patientType: z.nativeEnum(PetType),
});

export const updateResultSchema = z.object({
  objectiveId: z.string().uuid(),
  results: z
    .object({
      resultId: z.string().uuid(),
      isImportant: z.boolean(),
    })
    .array(),
  isFinished: z.boolean(),
});

export type Report = z.infer<typeof reportSchema>;

export type FullReport = z.infer<typeof fullReportSchema>;

export type CreateReport = z.infer<typeof createReportSchema>;
