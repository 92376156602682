import { Loader2 } from "lucide-react";

import { cn } from "@/utils";

const Loader = ({ className }: { className?: string }) => {
  return (
    <div className="w-full h-full grid place-items-center">
      <Loader2
        className={cn(
          "my-28 h-16 w-16 text-primary/60 dark:text-primary-dark/60 animate-spin",
          className
        )}
      />
    </div>
  );
};

export { Loader };
