import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MessageSquareWarning } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import { updateResultApi } from "@api/reports.api.ts";
import { getResultsApi } from "@api/results.api.ts";
import { ApiAlert } from "@components/api-alert.tsx";
import { EditObjectiveResults } from "@components/edit-objective-results.tsx";
import { Button } from "@components/ui/button.tsx";
import { Checkbox } from "@components/ui/checkbox.tsx";
import { Label } from "@components/ui/label.tsx";
import { useStepper } from "@components/ui/stepper.tsx";
import { SelectedResult } from "@schemas/objective.schema.ts";

interface ResultListProps {
  objectiveId: string;
  selectedResults: SelectedResult[];
  reportId: string;
  isLast: boolean;
}

export const ResultList = ({
  objectiveId,
  selectedResults: defaultSelectedResults,
  reportId,
  isLast,
}: ResultListProps) => {
  const [selectedResults, setSelectedResults] = useState(
    defaultSelectedResults.map(result => ({
      resultId: result.resultId,
      isImportant: result.isImportant,
    }))
  );

  const { data, isError, error } = useQuery({
    queryKey: ["results", objectiveId],
    queryFn: () => getResultsApi(objectiveId, reportId)(),
  });
  const updateResult = useMutation({
    mutationKey: ["update-result", reportId, objectiveId],
    mutationFn: updateResultApi(reportId),
  });

  const { nextStep } = useStepper();
  const queryClient = useQueryClient();

  useEffect(() => {
    setSelectedResults(
      defaultSelectedResults.map(result => ({
        resultId: result.resultId,
        isImportant: result.isImportant,
      }))
    );
  }, [defaultSelectedResults]);

  const handleNextClick = useCallback(async () => {
    if (
      selectedResults.length !== defaultSelectedResults.length ||
      isLast ||
      !defaultSelectedResults.every(
        result =>
          selectedResults.findIndex(r => r.resultId === result.resultId) > -1
      )
    ) {
      await updateResult.mutateAsync({
        objectiveId,
        results: selectedResults,
        isFinished: isLast,
      });
      await queryClient.invalidateQueries({
        queryKey: ["reports", reportId],
      });
    }

    nextStep();
  }, [
    isLast,
    nextStep,
    objectiveId,
    queryClient,
    reportId,
    selectedResults,
    defaultSelectedResults,
    updateResult,
  ]);

  return (
    <div className="px-8 py-4 rounded bg-slate-200 dark:bg-slate-900 lg:w-1/2">
      {isError && <ApiAlert error={error} />}
      {data && (
        <>
          <div className="grid gap-4">
            {data.map(result => (
              <div className="flex gap-8 items-center" key={result.id}>
                <div className="flex gap-2">
                  <Checkbox
                    id={result.id}
                    checked={
                      selectedResults.findIndex(r => r.resultId === result.id) >
                      -1
                    }
                    onCheckedChange={checked => {
                      if (checked) {
                        return setSelectedResults(prev => [
                          ...prev,
                          { resultId: result.id, isImportant: false },
                        ]);
                      }

                      return setSelectedResults(prev =>
                        prev.filter(r => r.resultId !== result.id)
                      );
                    }}
                  />
                  <Label htmlFor={result.id}>{result.value}</Label>
                </div>
                {selectedResults.findIndex(r => r.resultId === result.id) >
                  -1 && (
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      checked={
                        selectedResults.find(r => r.resultId === result.id)
                          ?.isImportant
                      }
                      onChange={e => {
                        setSelectedResults(prev =>
                          prev.map(r =>
                            r.resultId === result.id
                              ? { ...r, isImportant: e.target.checked }
                              : r
                          )
                        );
                      }}
                      className="hidden peer/important"
                      id={`important-${result.id}`}
                    />
                    <Label
                      title="Bold it up!"
                      className="flex items-center gap-1 cursor-pointer peer-checked/important:text-lime-600"
                      htmlFor={`important-${result.id}`}
                    >
                      <MessageSquareWarning size={24} />
                      Bold
                    </Label>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex gap-4 mt-6 justify-end">
            <EditObjectiveResults
              results={data}
              reportId={reportId}
              objectiveId={objectiveId}
            />
            <Button onClick={handleNextClick}>
              {isLast ? "Finish" : "Next"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
