import axiosInstance from "@config/axiosInstance.ts";
import router from "@router.tsx";
import { useUserStore } from "@stores";

const login = (token: string) => {
  localStorage.setItem(import.meta.env.VITE_TOKEN_KEY, token);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const logout = async () => {
  localStorage.removeItem(import.meta.env.VITE_TOKEN_KEY);
  axiosInstance.defaults.headers.common.Authorization = "";
  useUserStore.setState({ user: undefined });
  await router.navigate({
    to: "/login",
  });
};

export default {
  login,
  logout,
};
