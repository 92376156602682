import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { DownloadIcon, Mail } from "lucide-react";
import { useCallback } from "react";
import { toast } from "sonner";

import { generateEmailApi, generateFileApi } from "@api/reports.api.ts";
import { Button } from "@components/ui/button.tsx";
import { useStepper } from "@components/ui/stepper.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";

interface ObjectiveFooterProps {
  reportId: string;
}

export const ObjectiveFooter = ({ reportId }: ObjectiveFooterProps) => {
  const { activeStep, steps } = useStepper();
  const navigate = useNavigate();

  const generateFile = useMutation({
    mutationKey: ["generate", "file", reportId],
    mutationFn: generateFileApi(reportId),
  });

  const generateEmail = useMutation({
    mutationKey: ["generate", "email", reportId],
    mutationFn: generateEmailApi(reportId),
  });

  const handleFileExport = useCallback(async () => {
    const { downloadUrl } = await generateFile.mutateAsync();

    toast(
      "File has been generated and should start downloading any second now."
    );

    window.open(downloadUrl, "__blank");
    await navigate({
      to: "/",
    });
  }, [generateFile, navigate]);

  const handleEmailExport = useCallback(async () => {
    await generateEmail.mutateAsync();

    toast("Email has been generated and sent to you.", {
      description: "This might take a few minutes to process.",
    });
    await navigate({
      to: "/",
    });
  }, [generateEmail, navigate]);

  if (activeStep !== steps.length) return null;

  return (
    <div className="flex gap-4 mt-14 justify-end">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={handleFileExport}>
              <DownloadIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Download report as file</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={handleEmailExport}>
              <Mail />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Send report to an email</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
