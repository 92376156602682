import { z } from "zod";

import {
  createPersonalizedResultsSchema,
  createUserResultSchema,
  resultSchema,
  updateUserResultOrderSchema,
} from "@schemas/result.schema.ts";
import api from "@utils/api.util.ts";

export const getResultsApi = (objectiveId: string, reportId?: string) =>
  api({
    method: "get",
    path: `/results/${objectiveId}${reportId ? `?reportId=${reportId ?? ""}` : ""}`,
    requestSchema: z.void(),
    responseSchema: resultSchema.array(),
  });

export const createUserResultApi = (objectiveId: string) =>
  api({
    method: "post",
    path: `/results/user/${objectiveId}`,
    requestSchema: createUserResultSchema,
    responseSchema: resultSchema,
  });

export const updateUserResultApi = (objectiveId: string, resultId: string) =>
  api({
    method: "put",
    path: `/results/user/${objectiveId}/${resultId}`,
    requestSchema: createUserResultSchema,
    responseSchema: resultSchema,
  });

export const createPersonalizedResultsApi = api({
  method: "post",
  path: "/results/personalized",
  requestSchema: createPersonalizedResultsSchema,
  responseSchema: z.any(),
});

export const updateUserResultOrderApi = (
  objectiveId: string,
  resultId: string
) =>
  api({
    method: "put",
    path: `/results/user/${objectiveId}/${resultId}/order`,
    requestSchema: updateUserResultOrderSchema,
    responseSchema: resultSchema,
  });

export const deleteUserResultApi = (objectiveId: string, resultId: string) =>
  api({
    method: "delete",
    path: `/results/user/${objectiveId}/${resultId}`,
    requestSchema: z.void(),
    responseSchema: resultSchema,
  });
