import { z } from "zod";

import {
  forgotPasswordRequestSchema,
  loginRequestSchema,
  loginResponseSchema,
  registerRequestSchema,
  registerResponseSchema,
  resetPasswordRequestSchema,
  userSchema,
} from "@schemas/auth.schema.ts";
import api from "@utils/api.util.ts";

export const loginApi = api({
  method: "post",
  path: "/auth/login",
  requestSchema: loginRequestSchema,
  responseSchema: loginResponseSchema,
});

export const registerApi = api({
  method: "post",
  path: "/auth/register",
  requestSchema: registerRequestSchema,
  responseSchema: registerResponseSchema,
});

export const meApi = api({
  method: "get",
  path: "/auth/me",
  requestSchema: z.void(),
  responseSchema: userSchema,
});

export const forgotPasswordApi = api({
  method: "post",
  path: "/auth/forgot-password",
  requestSchema: forgotPasswordRequestSchema,
  responseSchema: z.any(),
});

export const resetPasswordApi = api({
  method: "patch",
  path: "/auth/reset-password",
  requestSchema: resetPasswordRequestSchema,
  responseSchema: z.any(),
});
