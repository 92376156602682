import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

import { forgotPasswordApi } from "@api/auth.api.ts";
import { Button } from "@components/ui/button.tsx";
import { Card, CardContent, CardHeader } from "@components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form.tsx";
import { Input } from "@components/ui/input.tsx";
import {
  forgotPasswordRequestSchema,
  ForgotPassword,
} from "@schemas/auth.schema.ts";

const ForgotPasswordRoute = () => {
  const forgotPassword = useMutation({
    mutationFn: forgotPasswordApi,
  });

  const form = useForm<ForgotPassword>({
    resolver: zodResolver(forgotPasswordRequestSchema),
    defaultValues: {
      email: "",
    },
  });

  const handleSubmit = useCallback(
    async (values: ForgotPassword) => {
      await forgotPassword.mutateAsync(values);

      toast.success("Check your email", {
        description: "We sent instructions to reset your password.",
      });
    },
    [forgotPassword]
  );

  return (
    <div className="h-full grid place-items-center">
      <Card className="min-w-[30rem] max-w-[30rem]">
        <CardHeader className="text-center">
          <h1 className="text-2xl">Forgot Password?</h1>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              className="space-y-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <p className="text-slate-950/50 dark:text-slate-50/50">
                Include the email address associated with your account and we’ll
                send you an email with instructions to reset your password.
              </p>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        inputMode="email"
                        placeholder="example@example.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button>Send reset instructions</Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ForgotPasswordRoute;
