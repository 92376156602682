import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { meApi } from "@api/auth.api.ts";
import { useUserStore } from "@stores";

interface AuthorizationProps {
  children: React.ReactNode;
}

const Authorization = ({ children }: AuthorizationProps) => {
  const { data, isSuccess } = useQuery({
    queryFn: () => meApi(),
    queryKey: ["me"],
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
  const setUser = useUserStore(state => state.setUser);

  useEffect(() => {
    if (isSuccess) {
      setUser(data);
    }
  }, [isSuccess]);

  if (!isSuccess) return null;

  return children;
};

export default Authorization;
