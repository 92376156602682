import { redirect, RootRoute, Route, Router } from "@tanstack/react-router";
import { z } from "zod";

import HomeLayout from "@layouts/home.layout.tsx";
import PreferencesLayout from "@layouts/preferences.layout.tsx";
import RootLayout from "@layouts/root.layout.tsx";
import ForgotPasswordRoute from "@routes/auth/forgot-password.route.tsx";
import LoginRoute from "@routes/auth/login.route.tsx";
import RegisterRoute from "@routes/auth/register.route.tsx";
import ResetPasswordRoute from "@routes/auth/reset-password.route.tsx";
import VerificationSentRoute from "@routes/auth/verification-sent.route.tsx";
import HomeRoute from "@routes/home/home.route.tsx";
import AccountRoute from "@routes/home/preferences/account.route.tsx";
import RecordOptionsRoute from "@routes/home/preferences/record-options.route.tsx";
import RecordRoute from "@routes/home/record.route.tsx";
import { resetPasswordSearchSchema } from "@schemas/auth.schema.ts";

const rootLayout = new RootRoute({
  component: RootLayout,
});

export const loginRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/login",
  component: LoginRoute,
  validateSearch: z.object({
    oneTimeToken: z.string().optional(),
    canceled: z.boolean().optional(),
  }),
});

const registerRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/register",
  component: RegisterRoute,
});

const forgotPasswordRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/forgot-password",
  component: ForgotPasswordRoute,
});

const resetPasswordRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/reset-password",
  component: ResetPasswordRoute,
  validateSearch: params => resetPasswordSearchSchema.parse(params),
  onError: () => {
    throw redirect({ to: "/" });
  },
});

const verificationSentRoute = new Route({
  getParentRoute: () => authRoute,
  path: "/verification-sent",
  component: VerificationSentRoute,
});

const authRoute = new Route({
  id: "auth",
  getParentRoute: () => rootLayout,
  beforeLoad: () => {
    if (localStorage.getItem(import.meta.env.VITE_TOKEN_KEY)) {
      throw redirect({
        to: "/",
      });
    }
  },
});

const homeLayout = new Route({
  id: "home-layout",
  getParentRoute: () => rootLayout,
  beforeLoad: () => {
    if (!localStorage.getItem(import.meta.env.VITE_TOKEN_KEY)) {
      throw redirect({
        to: "/login",
      });
    }
  },
  component: HomeLayout,
});

const homeRoute = new Route({
  path: "/",
  getParentRoute: () => homeLayout,
  component: HomeRoute,
});

const recordRoute = new Route({
  path: "/record/$id",
  getParentRoute: () => homeLayout,
  component: RecordRoute,
});

const preferencesLayout = new Route({
  id: "preferences-layout",
  getParentRoute: () => homeLayout,
  component: PreferencesLayout,
});

const preferencesRoute = new Route({
  path: "/preferences",
  getParentRoute: () => preferencesLayout,
  beforeLoad: async ({ navigate }) => {
    await navigate({ to: "/preferences/account", replace: true });
  },
});

const accountRoute = new Route({
  path: "/preferences/account",
  getParentRoute: () => preferencesLayout,
  component: AccountRoute,
});

const recordOptionsRoute = new Route({
  path: "/preferences/record-options",
  getParentRoute: () => preferencesLayout,
  component: RecordOptionsRoute,
});

const routeTree = rootLayout.addChildren([
  authRoute.addChildren([
    loginRoute,
    registerRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    verificationSentRoute,
  ]),
  homeLayout.addChildren([homeRoute, recordRoute]),
  preferencesLayout.addChildren([
    preferencesRoute,
    accountRoute,
    recordOptionsRoute,
  ]),
]);

const router = new Router({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default router;
