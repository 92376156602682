import { useNavigate } from "@tanstack/react-router";

import { useTheme } from "@components/theme-provider.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { ModeToggle } from "@components/ui/mode-toggle.tsx";
import { useUserStore } from "@stores";
import authUtil from "@utils/auth.util.ts";

export const Navbar = () => {
  const user = useUserStore(state => state.user);
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();

  return (
    <div className="flex justify-end items-center gap-4 p-6 fixed top-0 right-0 z-50">
      {!user && <ModeToggle />}
      {user && (
        <>
          <h3 className="text-slate-950 dark:text-slate-50">{user.name}</h3>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="outline-0">
                <img
                  className="border border-slate-950 dark:border-slate-50 rounded-full h-10"
                  src={user.avatarUrl}
                  alt="avatar"
                />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Mode</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup value={theme}>
                <DropdownMenuRadioItem
                  className="cursor-pointer"
                  value="light"
                  onClick={() => setTheme("light")}
                >
                  Light
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  className="cursor-pointer"
                  value="dark"
                  onClick={() => setTheme("dark")}
                >
                  Dark
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  className="cursor-pointer"
                  value="system"
                  onClick={() => setTheme("system")}
                >
                  System
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() =>
                  navigate({
                    to: "/preferences",
                  })
                }
              >
                Preferences
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={authUtil.logout}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
    </div>
  );
};
